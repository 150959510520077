<template>
    <!-- 页面主体区域 -->
    <el-container>
        <el-main style="padding: 0">
            <div class="wxtruckmap-wrap">
                <div class="info-window">
                    <div class="flex-div info-header">
                        <div class="label">车牌：</div>
                        <el-select v-model="curTruckLicense" size="mini">
                            <el-option
                                v-for="license in queryLicenseArr"
                                :key="license"
                                :label="license"
                                :value="license"
                            ></el-option>
                        </el-select>
                    </div>
                    <template v-if="!isCollapse">
                        <template v-if="!licenseExpiredEnum[curTruckLicense]">
                            <div class="info-item" style="margin-top: 12px">
                                <div class="label">当前位置:</div>
                                <div>{{curAddressInfo.showAddress}}</div>
                            </div>
                            <div class="info-item">
                                <div class="label">最后更新时间:</div>
                                <div>{{lastUpdateTime}}</div>
                            </div>
                            <div class="info-item">
                                <div class="label">当前车速:</div>
                                <div>{{`${curTruckSpeed}${curTruckSpeed !== '' ?  'km / h' : ''}`}}</div>
                            </div>
                            <div class="info-item">
                                <div class="label">备注:</div>
                                <div>{{curRemark}}</div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="license-expired-wrap">
                                <div>车牌: {{ curTruckLicense }} 订阅已失效</div>
                                <div>如需继续查看，请
                                    <el-button type="text" size="mini" @click="reSubVisible=true">重新订阅</el-button>
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="info-footer">
                        <el-button type="text" size="mini" @click="handleCollapse">{{isCollapse ? '展开' : '收起'}}</el-button>
                    </div>
                </div>
                <el-dialog title="提示" :visible="reSubVisible" width="70%" :center="true" custom-class="reSub-dialog" @close="reSubVisible = false">
                    <div>确定重新订阅车牌: {{curTruckLicense}} 吗？</div>
                    <template #footer>
                        <el-row type="flex" justify="end">
                            <el-button size="mini" @click="reSubVisible=false">取消</el-button>
                            <el-button type="primary" size="mini" @click="handleReSubscribe">确定</el-button>
                        </el-row>
                    </template>
                </el-dialog>
                <el-dialog title="提示" :visible="emptyVisible" width="70%" :center="true" custom-class="tragectory-empty-dialog" @close="emptyVisible = false">
                    <div>当前车牌暂未查询到数据</div>
                    <template #footer>
                        <el-row type="flex" justify="end">
                            <el-button type="primary" size="mini" @click="emptyVisible = false">确定</el-button>
                        </el-row>
                    </template>
                </el-dialog>
                <div id="mapDiv" class="map"></div>
            </div>
        </el-main>
    </el-container>
</template>
<script>
import { apiCallback } from '@/utils';
import { getCarTrajectory,getCarSubscribeList } from '@/api/truckSubscribe';
import moment from 'moment';

export default {
    name: 'wxTruckmap',
    data() { 
        return {
            reSubVisible: false,
            emptyVisible: false,
            companyId:'', // 公司id
            queryLicenseArr: [], // 链接带过来的车牌列表
            licenseExpiredEnum: {}, // 车牌状态枚举
            lineArr: [], // 轨迹经纬度列表
            wholeLineArr: [], // 完整的轨迹信息（包括车速和更新时间）
            curAddressInfo: {
                
            },
            map: null,
            curTruckLicense: '', // 当前显示车牌
            curBlno:'', // 当前提单号
            lastUpdateTime: '', // 最后更新时间
            curTruckSpeed: '', // 当前车速
            curRemark: '', // 当前车牌备注
            remarkEnum: {}, // 车牌备注枚举
            isCollapse: false,
            curToken: '',
            huoche: require("@/assets/img/huoche2.png"),
            startIcon:
                "https://web.nbhuojian.com/static/fangcangwang/map/start.png", //起点图标
            endIcon:
                "https://web.nbhuojian.com/static/fangcangwang/map/end.png", //终点图标
        }
    },
    mounted() {
        this.initMap();
        const { blno, truckLicense, token, cid } = this.$route.query;
        this.curBlno = blno
        if (cid) { 
            this.companyId = cid
        }
        if (token) { 
            this.curToken = token
        }
        if (truckLicense) {
            const cacheArr = truckLicense.split(',')
            this.queryLicenseArr = cacheArr
        }
        this.$nextTick(() => {
            this.loadSubList(blno)
        })
    },
    watch:{
        curTruckLicense(val) {
            if (!this.licenseExpiredEnum[val]) {
                this.curRemark = this.remarkEnum[val]
                this.loadCarTrajectory({
                    truckLicense: val,
                    blno: this.curBlno
                })
            } else { 
                this.initMap()
            }
        }
    },
    methods: {
        checkExpired(newList) { 
            const cacheEnum = {}
            this.queryLicenseArr.forEach(item => { 
                const isExpired = newList.some((list) => { 
                    return list === item
                })
                cacheEnum[item] = !isExpired
            })
            this.licenseExpiredEnum = cacheEnum
        },
        loadSubList(blno) {
            getCarSubscribeList({
                blno
            },this.curToken).then(res => { 
                const data = apiCallback(res);
                const cacheArr = data.map(item => {
                    return item.truckLicense
                })
                const cacheEnum = data.reduce((acc, cur) => { 
                    acc[cur.truckLicense] = cur.remark
                    return acc
                }, {})
                this.remarkEnum = cacheEnum;
                this.checkExpired(cacheArr)
                this.curTruckLicense = this.queryLicenseArr[0]
            })
        },
        handleCollapse(){
            this.isCollapse = !this.isCollapse
        },
        getShowSpeed(time, speed) { 
            const timeDiff = moment(time).diff(moment(), 'minutes')
            let showSpeed
            if (timeDiff === 0) {
                showSpeed = speed
            } else { 
                showSpeed = 0
            }
            return showSpeed
        },
        loadCarTrajectory(data) {
            return new Promise((resolve) => {
                const reqParam = data
                if (this.companyId) { 
                    reqParam.companyId = this.companyId
                }
                getCarTrajectory(reqParam,this.curToken).then(res => {
                    const data = apiCallback(res)
                    if (!data || !data.length) { 
                        this.emptyVisible = true
                        this.initMap();
                    } else {
                        this.curTruckLicense = reqParam.truckLicense;
                        this.wholeLineArr = data.slice(-300);
                        const lastTarData = data.slice(-1)[0];
                        this.lastUpdateTime = lastTarData.time;
                        this.curTruckSpeed = this.getShowSpeed(lastTarData.time,lastTarData.speed);
                        this.showTruckTrack(data.slice(-300));  
                    }
                    resolve(res)
                }).catch(() => { 
                    this.reSubVisible = false
                })
            })
        },
        carpassOneNodeRecord(lnglat, index, length) {
            let lineArr = this.lineArr;
            if (index === length) {
                this._CarTrack.pause();
                //添加起始点
                var startMarker = new T.Marker(
                    new T.LngLat(lineArr[0][0], lineArr[0][1]),
                    {
                        icon: new T.Icon({
                            iconUrl: this.startIcon,
                            iconSize: new T.Point(44, 34),
                            iconAnchor: new T.Point(12, 31),
                        }),
                    },
                );
                this.map.addOverLay(startMarker);
                var endMarker = new T.Marker(
                    new T.LngLat(
                        lineArr[lineArr.length - 1][0],
                        lineArr[lineArr.length - 1][1],
                    ),
                    {
                        icon: new T.Icon({
                            iconUrl: this.endIcon,
                            iconSize: new T.Point(44, 34),
                            iconAnchor: new T.Point(12, 31),
                        }),
                    },
                );
                this.map.addOverLay(endMarker);
            }
        },
        addTrajectorySeppdInfo(onlyOne) { 
            const startLine = this.wholeLineArr[0];
            const endLine = this.wholeLineArr[this.wholeLineArr.length - 1];
            let lineLabelArr = [];
            if (onlyOne) {
                lineLabelArr = [endLine]
            } else { 
                lineLabelArr = [startLine,endLine]
            }
            lineLabelArr.forEach((item, index) => { 
                const curLnglat = new T.LngLat(item.lon, item.lat)
                const geocode = new T.Geocoder();
                geocode.getLocation(curLnglat, (result) => { 
                    const { addressComponent } = result;
                    const { province, city, county, address, poi } = addressComponent;
                    const pointTitle = index === 0 ? '起点' : '当前位置'
                    const appendStr = address === poi ? address : `${address}(${poi})`
                    const showAddress = `${province}${city}${county}${appendStr}`
                    const curLineLabel = new T.Label({
                        text: `<div class='way-point'><div>${pointTitle}:${showAddress}</div><div class='secondary-info'>时间:${item.time}</div><div class='secondary-info'>速度:${item.speed}km/h</div></div>`,
                        position: curLnglat,
                        offset: new T.Point(-100,-100)
                    })
                    if (!onlyOne && index === 1) {
                        this.curAddressInfo = {
                            ...addressComponent,
                            showAddress
                        }
                    } else if(onlyOne && index === 0) { 
                        this.curAddressInfo = {
                            ...addressComponent,
                            showAddress
                        }
                    }
                    this.map.addOverLay(curLineLabel)
                })
            })
        },
        searchResult(result) {
            if (result.start === result.end) {
                return;
            }
            this.map.clearOverLays();
            //添加起始点
            var startMarker = new T.Marker(result.getStart(), {
                icon: new T.Icon({
                    iconUrl: this.startIcon,
                    iconSize: new T.Point(44, 34),
                    iconAnchor: new T.Point(12, 31),
                }),
            });
            this.map.addOverLay(startMarker);
            var endMarker = new T.Marker(result.getEnd(), {
                icon: new T.Icon({
                    iconUrl: this.endIcon,
                    iconSize: new T.Point(44, 34),
                    iconAnchor: new T.Point(12, 31),
                }),
            });
            this.map.addOverLay(endMarker);
            this.addTrajectorySeppdInfo();
            //获取方案个数
            var routes = result.getNumPlans();
            for (var i = 0; i < routes; i++) {
                //获得单条驾车方案结果对象
                var plan = result.getPlan(i);
                this._CarTrack = new T.CarTrack(this.map, {
                    interval: 5,
                    speed: 10,
                    dynamicLine: false,
                    carstyle: {
                        display: true,
                        iconUrl: this.huoche,
                        width: 80,
                        height: 80,
                    },
                    Datas: plan.getPath(),
                    // Datas: cacheData,
                    polylinestyle: {
                        color: "red",
                        weight: 2,
                        width: 6,
                        opacity: 0.8,
                    },
                    passOneNode: this.carpassOneNodeRecord,
                });
            }
        },
        startAnimation() {
            if (!this.lineArr.length) return;
            let lineArr = this.lineArr;
            if (this._CarTrack) {
                this._CarTrack.clear();
            }
            var startLngLat = new T.LngLat(lineArr[0][0], lineArr[0][1]);
            var endLngLat = new T.LngLat(
                lineArr[lineArr.length - 1][0],
                lineArr[lineArr.length - 1][1],
            );
            if (
                startLngLat.lat == endLngLat.lat &&
                startLngLat.lng == endLngLat.lng
            ) {
                this.addTrajectorySeppdInfo(true)
                return;
            }
            let config = {
                policy: 0, //驾车策略
                onSearchComplete: this.searchResult, //检索完成后的回调函数
            };
            let drivingRoute = new T.DrivingRoute(this.map, config);
            //驾车路线搜索
            drivingRoute.search(startLngLat, endLngLat);
            setTimeout(() => {
                this._CarTrack && this._CarTrack.start();
            }, 300);
        },
        showTruckTrack(data) {
            let that = this;
            var lineArr = [];
            if (data.length) {
                for (var i = 0; i < data.length; i = i + 1) {
                    var lonlat = [];
                    lonlat[0] = data[i]["lon"]
                    lonlat[1] = data[i]["lat"]
                    lineArr.push(lonlat);
                }
            }
            this.lineArr = lineArr;
            // 初始化地图
            let map = that.map;
            map.centerAndZoom(
                new T.LngLat(
                    lineArr[lineArr.length - 1][0],
                    lineArr[lineArr.length - 1][1],
                ),
                12,
            );
            //创建图片对象
            var icon = new T.Icon({
                iconUrl: that.huoche,
                iconSize: new T.Point(80, 80),
            });
            //创建标注对象
            let endMarker = new T.Marker(
                new T.LngLat(
                    lineArr[lineArr.length - 1][0],
                    lineArr[lineArr.length - 1][1],
                ),
                {
                    icon: icon,
                },
            );
            //向地图上添加标注
            map.addOverLay(endMarker);
            this.startAnimation();
        },
        initMap() { 
            this.lastUpdateTime = '';
            this.curTruckSpeed = '';
            this.curAddressInfo = {};
            this.map = new T.Map("mapDiv", { minZoom: 4 });
            this.map.centerAndZoom(new T.LngLat(116.40769, 39.89945), 12);
        },
        handleShowTrack(row) {
            this.curTruckLicense = '';
            this.lastUpdateTime = '';
            this.curTruckSpeed = '';
            this.curAddressInfo = {};
            this.loadCarTrajectory(row);
        },
        handleReSubscribe() { 
            this.loadCarTrajectory({
                blno: this.curBlno,
                truckLicense: this.curTruckLicense
            }).then(() => { 
                this.licenseExpiredEnum[this.curTruckLicense] = false
                this.reSubVisible = false
            })
        }
    }
}
</script>

<style lang="stylus">
.wxtruckmap-wrap{
    height: 100%;
    position: relative;
    .info-window{
        width: 50%;
        min-width: 180px;
        max-width: 350px;
        background: #ffffff;
        box-shadow: 0 10rpx 15rpx -3rpx rgba(0,0,0,0.1);
        position:absolute;
        top: 20px;
        left: 20px;
        z-index: 2000;
        border-radius: 8px;
        overflow: hidden;
        padding: 8px 8px 0 8px;
        box-sizing: border-box;
        .flex-div{
            display: flex;
            align-items: center;
            .label{
                white-space: nowrap;
                font-size: 14px;
                font-weight: bold;
            }
        }
        .info-header{
            padding-bottom: 12px;
            border-bottom: 1px solid #DCDFE6;
        }
        .info-item{
            font-size: 12px;
        }
        .info-footer{
            display: flex;
            justify-content: center;
        }
    }
    .map{
        width : 100%;
        height: 100%;
    }
    .tdt-control-copyright.tdt-control{
        display: none;
    }
    .way-point{
        width: 200px;
        padding-top: 4px;
        div{
            margin-bottom: 4px;
            font-size: 12px;
            line-height: 14px;
            font-weight: bold;
            word-break: break-all;
            word-wrap: break-word;
            white-space: normal;
        }
        .secondary-info{
            font-weight: 400;
            color: #7a8699;
        }
    }
    .license-expired-wrap{
        padding-top: 8px;
        font-size: 12px;
        font-weight: bold;
        color: #F56C6C;
    }
}
.reSub-dialog,.tragectory-empty-dialog{
    .el-dialog__header{
        font-size: 16px;
    }
    .el-dialog__body{
        padding: 8px 20px;
    }
}
</style>
