import { request } from "@/utils/request";

const baseURL = "http://192.168.1.38:8080";

/**
 * 车辆轨迹查询（兼顾订阅功能）
 * @param { String } blno // 提单号
 * @param { String } truckLicense // 车牌号
 */
export const getCarTrajectory = (params, token) => {
    return request(
        "/car/trajectory",
        "post",
        params,
        {
            // baseURL,
            headers: {
                Authorization: token,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        false,
        true,
    );
};

/**
 * 集卡订阅列表
 * @param {String} blno // 提单号（非必填）
 */
export const getCarSubscribeList = (params, token) => {
    return request(
        "/car/getByBlno",
        "post",
        params,
        {
            // baseURL,
            headers: {
                Authorization: token,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        false,
        true,
    );
};

/**
 * 修改备注
 * @param {Number} id
 * @param {String} remark
 */
export const updateTruckSubRemark = (params, token) => {
    return request(
        "/car/updateRemark",
        "post",
        params,
        {
            // baseURL,
            headers: {
                Authorization: token,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        false,
        true,
    );
};

/**
 * 删除车牌
 * @param {Number} id
 */
export const deleteCarSubscribe = (params) => {
    return request(
        "/car/delete",
        "post",
        params,
        {
            // baseURL,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        false,
        true,
    );
};
